import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import flashMessagesModule from '../vuex_modules/flash_messages';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    },
    modules: {
        flashMessages: flashMessagesModule,
    },
});
