/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
// import VueAnalytics from 'vue-analytics'
import Router from 'vue-router';
// import Meta from 'vue-meta'

// Routes
import paths from './paths';
// guards
import guards from './globalguards';

Vue.use(Router);

// Create a new router
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: paths,
});

guards(router);

// Vue.use(Meta)

export default router;
