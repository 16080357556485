export default [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/Index.vue'),

        children: [
            {
                alias: '',
                name: 'select',
                path: 'select',
                component: () => import('@/views/SelectList.vue'),
            },
            {
                name: 'multiplication-1x1',
                path: 'multiplication-1x1',
                component: () => import('@/views/Multiplication.vue'),
                props: {
                    numberQuestions: 20,
                    firstMax: 9,
                    firstMin: 0,
                    secondMax: 9,
                    secondMin: 0,
                },
            },
            {
                name: 'multiplication-1x2',
                path: 'multiplication-1x2',
                component: () => import('@/views/Multiplication.vue'),
                props: {
                    numberQuestions: 20,
                    firstMax: 9,
                    firstMin: 0,
                    secondMax: 99,
                    secondMin: 0,
                },
            },
            {
                name: 'multiplication-2x2',
                path: 'multiplication-2x2',
                component: () => import('@/views/Multiplication.vue'),
                props: {
                    numberQuestions: 20,
                    firstMax: 99,
                    firstMin: 0,
                    secondMax: 99,
                    secondMin: 0,
                },
            },
            {
                name: 'multiplication-1x3',
                path: 'multiplication-1x3',
                component: () => import('@/views/Multiplication.vue'),
                props: {
                    numberQuestions: 20,
                    firstMax: 300,
                    firstMin: 0,
                    secondMax: 9,
                    secondMin: 0,
                },
            },
            {
                name: 'division-3dig-1dig',
                path: 'division-3dig-1dig',
                component: () => import('@/views/Division.vue'),
                props: {
                    numberQuestions: 20,
                    numeratorMax: 100,
                    numeratorMin: 0,
                    denominatorMax: 9,
                    denominatorMin: 1,
                    answerMax: 10,
                },
            },
            {
                name: 'division-3dig-2dig',
                path: 'division-3dig-2dig',
                component: () => import('@/views/Division.vue'),
                props: {
                    numberQuestions: 20,
                    numeratorMax: 100,
                    numeratorMin: 0,
                    denominatorMax: 20,
                    denominatorMin: 1,
                    answerMax: 20,
                },
            },
        ],
    },
    {
        path: '*',
        component: () => import('@/views/404.vue'),
        name: '404 Error',
    },
];
