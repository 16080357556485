export default {
    namespaced: true,
    state: {
        text: '',
        color: '',
        timeout: '',
        icon: '',
    },
    mutations: {
        SHOW_MESSAGE(state, payload) {
            state.text = payload.text;
            state.color = payload.color;
            state.timeout = payload.timeout;
            state.icon = payload.icon;
        },
    },
    actions: {
        flashMessage({ commit }, payload) {
            commit('SHOW_MESSAGE', payload);
        },
        flashInfo({ dispatch }, payload) {
            dispatch('flashMessage', {
                text: payload,
                color: 'info',
                timeout: 5000,
                icon: 'mdi-information-outline',
            });
        },
        flashSuccess({ dispatch }, payload) {
            dispatch('flashMessage', {
                text: payload,
                color: 'success',
                timeout: 5000,
                icon: 'mdi-check-circle-outline',
            });
        },
        flashError({ dispatch }, payload) {
            dispatch('flashMessage', {
                text: payload,
                color: 'error',
                timeout: 5000,
                icon: 'mdi-alert-octagon-outline',
            });
        },
        flashWarning({ dispatch }, payload) {
            dispatch('flashMessage', {
                text: payload,
                color: 'warning',
                timeout: 5000,
                icon: 'mdi-alert-outline',
            });
        },
    },
};
